<template>
  <div class="news">
    <div class="navTitle">资讯</div>
    <div class="news-search">
      <div class="search-box flex">
        <img src="@/assets/img/serch.png" alt="">
        <input type="text" placeholder="点击进入资讯搜索">
      </div>
    </div>
    <van-tabs  class="news-tab" v-model:active="active" color="#ff6600" title-active-color="#ff6600"
     swipeable sticky offset-top="22.5vw" 
     >
      <van-tab title="新闻动态">
        <van-list
          v-model:loading="news.loading"
          :finished="news.finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
        <router-link  v-for="item in news.list" :key="item" :to="'/newsContent/'+item.newsId" class="card flex">
          <div class="news-title">
            <p class="overflow2">{{item.newsTheme}}</p>
            <div class="flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 4vw;">{{item.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{item.orgName ? item.orgName : '桂信融'}}</span>            
            </div>
          </div>
          <!-- <img :src="store.state.imgURL + item.titleImgUrl" alt=""> -->
          <!-- <img src="@/assets/img/yhcp1.png" alt=""> -->
        </router-link>
        </van-list>
      </van-tab>
      <van-tab title="扶持政策">
        <van-list
          v-model:loading="policy.loading"
          :finished="policy.finished"
          finished-text="没有更多了"
          @load="onLoad1"
        >
        <router-link  v-for="item in policy.list" :key="item" :to="'/policyContent/'+item.policyId" class="card flex">
          <div class="news-title">
            <p class="overflow2">{{item.policyTheme}}</p>
            <div class="flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 4vw;">{{item.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{item.orgName ? item.orgName : '桂信融'}}</span>            
            </div>
          </div>
          <!-- <img :src="store.state.imgURL + item.titleImgUrl" alt=""> -->
          <!-- <img src="@/assets/img/yhcp1.png" alt=""> -->
        </router-link>
        </van-list>
      </van-tab>
      <van-tab title="金融知识">
        <van-list
          v-model:loading="knowledge.loading"
          :finished="knowledge.finished"
          finished-text="没有更多了"
          @load="onLoad2"
        >
        <router-link  v-for="item in knowledge.list" :key="item" :to="'/knowledgeContent/'+item.knowledgeId" class="card flex">
          <div class="news-title">
            <p class="overflow2">{{item.knowledgeTheme}}</p>
            <div class="flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 4vw;">{{item.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{item.orgName ? item.orgName : '桂信融'}}</span>            
            </div>
          </div>
          <!-- <img :src="store.state.imgURL + item.titleImgUrl" alt=""> -->
          <!-- <img src="@/assets/img/yhcp1.png" alt=""> -->
        </router-link>
        </van-list>
      </van-tab>
      <van-tab title="通知公告">
        <van-list
          v-model:loading="notice.loading"
          :finished="notice.finished"
          finished-text="没有更多了"
          @load="onLoad3"
        >
        <router-link  v-for="item in notice.list" :key="item" :to="'/noticeContent/'+item.noticeId" class="card flex">
          <div class="news-title">
            <p class="overflow2">{{item.noticeTheme}}</p>
            <div class="flex">
              <img src="@/assets/img/time.png">
              <span style="margin-right: 4vw;">{{item.publishTime}}</span>            
              <img src="@/assets/img/link.png">
              <span>{{item.orgName ? item.orgName : '桂信融'}}</span>            
            </div>
          </div>
          <!-- <img :src="store.state.imgURL + item.titleImgUrl" alt=""> -->
          <!-- <img src="@/assets/img/yhcp1.png" alt=""> -->
        </router-link>
        </van-list>
      </van-tab>
    </van-tabs>
    
    <div style="height: 15vw; width:100%"></div>
    <TabBar v-if="!store.state.isiOS" nav="news"/>
  </div>
</template>
<script>
import TabBar from '@/components/TabBar.vue';
import { ref,watch,reactive, toRefs,onUnmounted } from 'vue';
import { apiNewsList,apiPolicyList,apiKnowledgeList,apiNoticeList } from '@/api/axios';
import { Toast } from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
   setup() {
    let store = useStore();
    if(store.state.isiOS) {
      try {
        window.webkit.messageHandlers.showBar.postMessage(null);
      } catch (e) {
        console.log(e);
      }
    }
    let router = useRouter();
    let type = router.currentRoute.value.params.type;
    const active = ref(0);
    active.value = parseInt(type);
    const state = reactive({
        news: {
          list: [],
          loading: false,
          finished: false,
          pageNo: 1,
          totalPage: 0
        },
        policy: {
          list: [],
          loading: false,
          finished: false,
          pageNo: 1,
          totalPage: 0
        },
        knowledge: {
          list: [],
          loading: false,
          finished: false,
          pageNo: 1,
          totalPage: 0
        },
        notice: {
          list: [],
          loading: false,
          finished: false,
          pageNo: 1,
          totalPage: 0
        },
        
    });
    const onLoad = () => {
          apiNewsList({pageNo: state.news.pageNo}).then(res => {                   
             if(res.code == '1'){
                 res.info.resultList.forEach(e => {
                    state.news.list.push(e);
                 });                
                if( state.news.pageNo === 1) {
                  state.news.totalPage = res.info.page.totalPage;
                  state.news.finished = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(state.news.pageNo >= state.news.totalPage){
                  state.news.finished = true;
                }else {
                  state.news.pageNo++;
                  // 加载状态结束
                  state.news.loading = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
    const onLoad1 = () => {
          apiPolicyList({pageNo: state.policy.pageNo}).then(res => {                 
             if(res.code == '1'){
                 res.info.resultList.forEach(e => {
                    state.policy.list.push(e);
                 });                
                if( state.policy.pageNo === 1) {
                  state.policy.totalPage = res.info.page.totalPage;
                  state.policy.finished = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(state.policy.pageNo >= state.policy.totalPage){
                  state.policy.finished = true;
                }else {
                  state.policy.pageNo++;
                  // 加载状态结束
                  state.policy.loading = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
    const onLoad2 = () => {
          apiKnowledgeList({pageNo: state.knowledge.pageNo}).then(res => {                 
             if(res.code == '1'){
                 res.info.resultList.forEach(e => {
                    state.knowledge.list.push(e);
                 });                
                if( state.knowledge.pageNo === 1) {
                  state.knowledge.totalPage = res.info.page.totalPage;
                  state.knowledge.finished = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(state.knowledge.pageNo >= state.knowledge.totalPage){
                  state.knowledge.finished = true;
                }else {
                  state.knowledge.pageNo++;
                  // 加载状态结束
                  state.knowledge.loading = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
    const onLoad3 = () => {
          apiNoticeList({pageNo: state.notice.pageNo}).then(res => {                
             if(res.code == '1'){
                 res.info.resultList.forEach(e => {
                    state.notice.list.push(e);
                 });                
                if( state.notice.pageNo === 1) {
                  state.notice.totalPage = res.info.page.totalPage;
                  state.notice.finished = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(state.notice.pageNo >= state.notice.totalPage){
                  state.notice.finished = true;
                }else {
                  state.notice.pageNo++;
                  // 加载状态结束
                  state.notice.loading = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
    /*监听props*/
    // watch(active,(newProps, oldProps) => {
    //   console.log(newProps,999)
    // });
    //卸载
    onUnmounted(()=>{
      if(store.state.isiOS) {
        try {
          window.webkit.messageHandlers.hideBar.postMessage(null);
        } catch (e) {
          console.log(e);
        }
      }
    })

    return {
      store,
      ...toRefs(state),
      onLoad,
      onLoad1,
      onLoad2,
      onLoad3,
      active
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },
  components: {
    TabBar
  }
}
</script>
<style lang="less" scoped>
.news {
  padding-top: 22.5vw;
  .news-search {
    position: fixed;
    top: 12.5vw;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    padding: 0 4vw 2vw;
    box-sizing: border-box;
    z-index: 998;
  }
  .news-tab {
    .card {
    .news-title {
      // flex:1;
      // padding-right: 6vw;
      width: 100%;
      p {
        font-size: 4vw;
        color: #333;
      }
      .flex {
        font-size: 3.3vw;
        color: #ccc;
        margin-top: 3vw;
        img {
          width: 3.5vw;
          height: auto;
          margin-right: 1vw;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    img {
      height: 18.3vw;
      width: auto;
    }
  }
    .news-page {
      padding: 0 5vw;
      // div {
      //   width: 10%;
      //   font-size: 4vw;
      //   background-color: #fff;
      //   text-align: center;
      //   height: 40px;
      //   line-height: 40px;
      //   display: none;
      // }
      .pagination {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="less">
.van-pagination__item {
  color: #333;
}
.van-pagination__item:active {
    background: none;
    // color: #fff;
}
.van-pagination__item--active {
  background: linear-gradient(to right, #ff9700, #ff6800);
  color: #fff;
}
// .van-pagination__next, .van-pagination__prev {
//   display: none;
// }
</style>